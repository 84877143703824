import React from "react";
import styled from "styled-components";

import { Line } from "./ComponentsLibrary";

import { useTranslation } from "react-i18next";

const FAQContainer = styled.main`
  padding: 2rem 10rem;
  background-color: #3c6d43;
  color: white;

  @media (max-width: 1250px) {
    padding: 1rem 10rem;
  }

  @media (max-width: 768px) {
    padding: 1rem;
  }
`;

const FAQStyled = styled.main`
  display: grid;
  grid-template-columns: 2fr 2fr;
  grid-gap: 20px;
  margin: 2rem 0;

  p {
    margin-bottom: 0.5rem;
  }

  h2 {
    margin-bottom: 1rem;
  }

  @media (max-width: 1250px) {
    grid-template-columns: 2fr;
    grid-gap: 10px;
    margin: 0;
    h2 {
      margin-top: 1rem;
    }
  }
`;

const FAQComponent = styled.main`
  display: flex;
  flex-direction: column;
  align-items: start;
  border-bottom: 1px solid;
  border-color: rgb(169, 169, 169, 0.3);

  @media (max-width: 1250px) {
    grid-template-rows: 1fr 1.2fr;
  }
`;

export default function FAQ() {
  const { t } = useTranslation();

  return (
    <FAQContainer>
      <h1 id="faq">{t("faqsTitle")}</h1>
      <FAQStyled>
        <FAQComponent>
          <h2>{t("faqsCostsQ")}</h2>
          <p>{t("faqsCostsA")}</p>
        </FAQComponent>

        <FAQComponent>
          <h2>{t("faqsEditHarvestersQ")}</h2>
          <p>{t("faqsEditHarvestersA")}</p>
          <Line />
        </FAQComponent>

        <FAQComponent>
          <h2>{t("faqsManagersSyncQ")}</h2>
          <p>{t("faqsManagersSyncA")}</p>
          <Line />
        </FAQComponent>

        <FAQComponent>
          <h2>{t("faqsMultipleProductsQ")}</h2>
          <p>{t("faqsMultipleProductsA")}</p>
          <Line />
        </FAQComponent>

        <FAQComponent>
          <h2>{t("faqsDifferentLocationsQ")}</h2>
          <p>{t("faqsDifferentLocationsA")}</p>
          <Line />
        </FAQComponent>

        <FAQComponent>
          <h2>{t("faqsOwnPhonesQ")}</h2>
          <p>{t("faqsOwnPhonesA")}</p>
          <Line />
        </FAQComponent>
      </FAQStyled>
    </FAQContainer>
  );
}
