import React from "react";
import "react-slideshow-image/dist/styles.css";

import Layout from "../components/layout";
import Slider from "../components/slider";
import SEO from "../components/seo";
import About from "../components/About";
import Benefits from "../components/Benefits";
import Parallax from "../components/Parallax";
import Testimonials from "../components/Testimonials";
import FAQ from "../components/FAQ";
import Contact from "../components/Contact";
import Footer from "../components/Footer";

const IndexPage = ({ pageContext }) => (
  <Layout pageContext={pageContext}>
    <SEO pageContext={pageContext} title="Smart Harvester" />
    <Slider />
    <About />
    <Benefits />
    <Parallax />
    <Testimonials />
    <FAQ />
    <Contact />
    <Footer />
  </Layout>
);

export default IndexPage;
