import React from "react";
import styled from "styled-components";
import Img from "gatsby-image";
import { useStaticQuery, graphql } from "gatsby";

import { Line } from "./ComponentsLibrary";
import { useTranslation } from "react-i18next";

const TestimonialsContainer = styled.main`
  padding: 2rem 20rem;

  @media (max-width: 1366px) {
    padding: 1rem 10rem;
  }

  @media (max-width: 768px) {
    padding: 1rem;
  }
`;

const AVATAR_QUERY = graphql`
  query avatars {
    testimonialMale: file(
      name: { eq: "testimonials-daniel" }
      extension: { eq: "png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 300, maxHeight: 300, pngQuality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    testimonialFemale: file(
      name: { eq: "testimonials-roxana" }
      extension: { eq: "png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 300, maxHeight: 300, pngQuality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`;

const TestimonialStyled = styled.main`
  display: grid;
  grid-template-columns: 0.9fr 2fr;
  margin: auto 1rem;
  align-items: start;

  @media (max-width: 1000px) {
    grid-template-columns: 2fr;
    grid-gap: 20px;
    margin: 0;
    align-items: center;
  }

  a {
    font-weight: 800;
    text-decoration: none;
    color: #663399;
  }

  a:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const StyledImg = styled(Img)`
  width: 150px;
  border-radius: 50%;
  @media (max-width: 1000px) {
    margin: 0px auto;
  }
`;

export default function Testimonials() {
  const data = useStaticQuery(AVATAR_QUERY);
  const { t } = useTranslation();

  return (
    <TestimonialsContainer>
      <h1 id="testimonials">{t("testimonialsTitle")}</h1>
      <TestimonialStyled>
        <StyledImg
          fluid={data.testimonialFemale.childImageSharp.fluid}
          alt="Roxana"
        />
        <span>
          <p style={{ fontStyle: "italic" }}>
            "{t("testimonialsRoxanaQuote")}"
          </p>
          <p>
            - {t("testimonialsRoxanaOwner")}
            <a
              href="https://www.facebook.com/Ferma-Afine-Bio-Rediu-102950928160291"
              target="_blank"
              rel="noreferrer nofollow"
            >
              Afine Bio Rediu
            </a>
            {t("testimonialsRoxanaDescription")}
          </p>
        </span>
        <Line />
      </TestimonialStyled>
      <TestimonialStyled>
        <StyledImg
          fluid={data.testimonialMale.childImageSharp.fluid}
          alt="Daniel"
        />

        <span>
          <p style={{ fontStyle: "italic" }}>
            "{t("testimonialsDanielQuote")}"
          </p>
          <p>
            - {t("testimonialsDanielOwner")}
            <a
              href="http://genesis-agro.ro/"
              target="_blank"
              rel="noreferrer nofollow"
            >
              Genesis Group
            </a>
            {t("testimonialsDanielDescription")}
          </p>
        </span>
        <Line />
      </TestimonialStyled>
    </TestimonialsContainer>
  );
}
