import React from "react";
import styled from "styled-components";
import Img from "gatsby-image";
import { useStaticQuery, graphql } from "gatsby";

import LocalizedLink from "./LocalizedLink";
import { useTranslation } from "react-i18next";
import { FooterFlexColumnContainer } from "./ComponentsLibrary";
import NewsletterForm from "./NewsletterForm";

export default ({ onAnchorLinkClick }) => {
  const data = useStaticQuery(FOOTER_QUERY);
  const { t, i18n } = useTranslation();

  let linkTerms = i18n.language === "en" ? 'https://smart-harvester.s3.eu-central-1.amazonaws.com/policies/Terms+and+Conditions.pdf' :
   'https://smart-harvester.s3.eu-central-1.amazonaws.com/policies/Termeni+%C8%99i+condi%C8%9Bii.pdf';
   
  let linkPolicy = i18n.language === "en" ? 'https://smart-harvester.s3.eu-central-1.amazonaws.com/policies/Privacy+Policy.pdf' :
   'https://smart-harvester.s3.eu-central-1.amazonaws.com/policies/Politica+de+confiden%C8%9Bialitate.pdf';

  return (
    <FooterStyled>
      <GridStyled id="footer">
        <FooterFlexMenuContainer>
          <NavItem to="/#aboutUs" onAnchorLinkClick={onAnchorLinkClick}>
            {t("navbarAboutUs")}
          </NavItem>
          <NavItem to="/#benefits" onAnchorLinkClick={onAnchorLinkClick}>
            {t("navbarBenefits")}
          </NavItem>
          <NavItem to="/#status" onAnchorLinkClick={onAnchorLinkClick}>
            {t("navbarDevelopmentStatus")}
          </NavItem>
          <NavItem to="/#testimonials" onAnchorLinkClick={onAnchorLinkClick}>
            {t("navbarTestimonials")}
          </NavItem>
          <NavItem to="/#faq" onAnchorLinkClick={onAnchorLinkClick}>
            {t("navbarFaq")}
          </NavItem>
          <NavItem to="/#contact" onAnchorLinkClick={onAnchorLinkClick}>
            {t("navbarContact")}
          </NavItem>
        </FooterFlexMenuContainer>
        <FooterFlexColumnContainer>
          <p>{t("footerFollow")}</p>
          <FollowIconsContainer>
            <a
              href={`https://www.facebook.com/smartharvester21`}
              target="_blank"
              rel="noreferrer"
            >
              <ClickableImg
                fixed={data.Facebook.childImageSharp.fixed}
                alt="Facebook"
              />
            </a>
            <a
              href={`https://www.instagram.com/smartharvester21/`}
              target="_blank"
              rel="noreferrer"
            >
              <ClickableImg
                fixed={data.Instagram.childImageSharp.fixed}
                alt="Instagram"
              />
            </a>
            <a
              href={`https://www.youtube.com/channel/UCrnOMl8CuDqYMQjUl-Fj3Eg/featured`}
              target="_blank"
              rel="noreferrer"
            >
              <ClickableImg
                fixed={data.Youtube.childImageSharp.fixed}
                alt="Youtube"
              />
            </a>
          </FollowIconsContainer>
        </FooterFlexColumnContainer>
        <NewsletterForm />
        <FooterFlexColumnContainer>
          <p>{t("footerContact")}</p>
          <ContactDetailsComponent>
            <Img fixed={data.Phone.childImageSharp.fixed} alt="Phone" />
            <p>(+40) 0724 676 173</p>
          </ContactDetailsComponent>
          <ContactDetailsComponent>
            <Img fixed={data.Mail.childImageSharp.fixed} alt="Mail" />
            <p>contact@smart-harvester.com</p>
          </ContactDetailsComponent>
          <ContactDetailsComponent>
            <Img fixed={data.Map.childImageSharp.fixed} alt="Map" />
            <p>{t("footerAddress")}</p>
          </ContactDetailsComponent>
        </FooterFlexColumnContainer>
      </GridStyled>
      <hr></hr>
      <GridStyledText>
        <FooterFlexColumnContainer>
          <p>
            Copyright © {new Date().getFullYear()}
            {` `} Smart Harvester
          </p>
        </FooterFlexColumnContainer>
        <FlexComponentPoliciesLinks>
          <a href={linkTerms} target="_blank" rel="noreferrer">
            {t("footerTerms")}
          </a>
          <a href={linkPolicy} target="_blank" rel="noreferrer">
            {t("footerPrivacy")}
          </a>
        </FlexComponentPoliciesLinks>
      </GridStyledText>
    </FooterStyled>
  );
};

const FOOTER_QUERY = graphql`
  query footer {
    Facebook: file(name: { eq: "facebook" }, extension: { eq: "png" }) {
      childImageSharp {
        fixed(width: 40, height: 40) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    Instagram: file(name: { eq: "instagram" }, extension: { eq: "png" }) {
      childImageSharp {
        fixed(width: 40, height: 40) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    Youtube: file(name: { eq: "youtube" }, extension: { eq: "png" }) {
      childImageSharp {
        fixed(width: 40, height: 40) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    Phone: file(name: { eq: "footer-phone-call" }, extension: { eq: "png" }) {
      childImageSharp {
        fixed(width: 20, height: 20) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    Mail: file(name: { eq: "footer-mail" }, extension: { eq: "png" }) {
      childImageSharp {
        fixed(width: 20, height: 20) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    Map: file(name: { eq: "footer-map" }, extension: { eq: "png" }) {
      childImageSharp {
        fixed(width: 20, height: 20) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
  }
`;

const ClickableImg = styled(Img)`
  @media (min-width: 768px) {
    img {
      filter: grayscale(1);
    }

    img:hover {
      filter: grayscale(0);
      cursor: pointer;
    }
  }
`;

const FooterStyled = styled.main`
  background-color: #1f1f1f;
  color: white;
  padding: 1rem 10rem;
  font-size: 0.7rem;

  @media (max-width: 1250px) {
    padding: 1rem 6rem;
  }

  @media (max-width: 768px) {
    padding: 1rem;
  }

  p {
    font-size: 0.7rem;
  }

  a {
    color: #fff;
    text-decoration: none;
  }

  a:hover {
    color: white;
    cursor: pointer;
    text-decoration: underline;
  }
`;

const FollowIconsContainer = styled.main`
  display: flex;
  justify-content: space-between;
  width: 150px;
`;

const ContactDetailsComponent = styled.main`
  display: grid;
  grid-template-columns: 1fr 4fr;
  width: 211px;
`;

const GridStyled = styled.main`
  display: grid;
  grid-template-columns: 2fr 2fr 2fr 2fr;
  grid-gap: 20px;

  p {
    margin-bottom: 0.5rem;
  }

  @media (max-width: 768px) {
    grid-template-columns: 2fr;
    grid-gap: 10px;
    margin: 0;
  }
`;

const GridStyledText = styled(GridStyled)`
  display: grid;
  grid-template-columns: 2fr 2fr;

  @media (max-width: 768px) {
    grid-template-columns: 2fr;
    justify-items: center;
  }
`;

const FooterFlexMenuContainer = styled(FooterFlexColumnContainer)`
  @media (max-width: 1000px) {
    display: none;
  }
`;

const FlexComponentPoliciesLinks = styled.main`
  display: flex;
  justify-content: space-between;
  width: 300px;
`;

const NavItem = styled(LocalizedLink)`
  text-decoration: none;
  text-transform: uppercase;

  :hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;
