import React from "react";
import styled from "styled-components";
import { AnchorLink } from "gatsby-plugin-anchor-links";

const Button = styled(AnchorLink)`
  display: inline-block;
  border-radius: 3px;
  text-align: center;
  padding: 0.5rem 0;
  margin: 0.5rem 1rem;
  width: 12rem;
  border: 2px solid #fff;
  background: #3d3d6d;
  color: #fff;
  text-decoration: none;
  text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3);
  box-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3);

  :hover {
    background: #3c6d43;
    border: 2px solid #3c6d43;
    color: white;
    text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3);
    box-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3);
    cursor: pointer;
  }
`;

export default function ({ to, children }) {
  return <Button to={to}>{children}</Button>;
}
