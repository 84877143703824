import React from "react";
import { Fade } from "react-slideshow-image";
import styled from "styled-components";
import Img from "gatsby-image";
import { useStaticQuery, graphql } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import { useTranslation } from "react-i18next";

import Button from "./Button";

const Slider = () => {
  const data = useStaticQuery(SLIDE_QUERY);
  const { t } = useTranslation();

  const properties = {
    duration: 10000,
    transitionDuration: 500,
    infinite: true,
    indicators: false,
    arrows: true,
    pauseOnHover: true,
    prevArrow: (
      <ArrowLeft>
        <Img fixed={data.backArrow.childImageSharp.fixed} alt="back arrow" />
      </ArrowLeft>
    ),
    nextArrow: (
      <ArrowRight>
        <Img fixed={data.nextArrow.childImageSharp.fixed} alt="next arrow" />
      </ArrowRight>
    ),
  };

  return (
    <div className="slide-container">
      <Fade {...properties}>
        <SingleFade>
          <StyledBackgroundImage
            fluid={data.firstSlide.childImageSharp.fluid}
            alt="slide 1"
          >
            <StyledWrapper>
              <SlideText>{t("sliderMoreInfoEfficiency")}</SlideText>
              <Button to="/#contact">{t("sliderButtonContact")}</Button>
            </StyledWrapper>
          </StyledBackgroundImage>
        </SingleFade>
        <SingleFade>
          <StyledBackgroundImage
            fluid={data.secondSlide.childImageSharp.fluid}
            alt="slide 2"
          >
            <StyledWrapper>
              <SlideText>{t("sliderFollowDevelopment")}</SlideText>
              <Button to="/#footer">{t("sliderButtonSubscribe")}</Button>
            </StyledWrapper>
          </StyledBackgroundImage>
        </SingleFade>
        <SingleFade>
          <StyledBackgroundImage
            fluid={data.thirdSlide.childImageSharp.fluid}
            alt="slide 3"
          >
            <StyledWrapper>
              <SlideText>{t("sliderFollowFacebook")}</SlideText>
              <Button to="https://www.facebook.com/smartharvester21">
                {t("sliderButtonFollow")}
              </Button>
            </StyledWrapper>
          </StyledBackgroundImage>
        </SingleFade>
      </Fade>
    </div>
  );
};

export default Slider;

const SLIDE_QUERY = graphql`
  query slides {
    firstSlide: file(name: { eq: "blueberry" }, extension: { eq: "jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 3000, pngQuality: 2000) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    secondSlide: file(name: { eq: "vegetablebox" }, extension: { eq: "jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 3000, pngQuality: 2000) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    thirdSlide: file(name: { eq: "strawberry" }, extension: { eq: "jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3000, pngQuality: 2000) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    nextArrow: file(name: { eq: "next-arrow" }, extension: { eq: "png" }) {
      childImageSharp {
        fixed(width: 30, height: 30) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    backArrow: file(name: { eq: "back-arrow" }, extension: { eq: "png" }) {
      childImageSharp {
        fixed(width: 30, height: 30) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
  }
`;

const sliderHeight = "92vh";
const sliderMaxHeight = "700px";

const SingleFade = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-size: cover;
  max-height: ${sliderMaxHeight};
  max-width: 1920px;
  height: ${sliderHeight};
}
`;

const StyledBackgroundImage = styled(BackgroundImage)`
  width: 100%;
  height: ${sliderHeight};
`;

export const StyledWrapper = styled.div`
  display: flex;
  place-content: center;
  justify-content: center;
  flex-direction: column;
  align-items: left;
  margin-left: 15vw;
  margin-top: 8vh;

  height: ${sliderHeight};
  max-height: ${sliderMaxHeight};

  @media (max-width: 768px) {
    align-items: center;
    margin-left: 0;
  }
`;

const SlideText = styled.main`
  font-size: 3rem;
  text-align: left;
  color: white;
  max-width: 1000px;
  width: 60vw;
  line-height: 1.2;
  text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3);
  padding-bottom: 5rem;

  @media (max-width: 768px) {
    font-size: 1.9rem;
    width: 80vw;
    line-height: 1.5;
    text-align: center;
    padding-bottom: 3rem;
  }

  @media (orientation: landscape) and (max-height: 450px) {
    font-size: 1.5rem;
  }
`;

const Arrow = styled.div`
  width: 30px;
  :hover {
    cursor: pointer;
  }
`;

const ArrowLeft = styled(Arrow)`
  margin-right: -30px;
  padding-left: 3px;
`;

const ArrowRight = styled(Arrow)`
  margin-left: -30px;
  padding-right: 3px;
`;
