import React, { useState } from "react";
import styled, { css } from "styled-components";
import Img from "gatsby-image";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useStaticQuery, graphql } from "gatsby";

import LoadingGif from "../images/ajax-loader.gif";
import { EMAIL_REGEX, PHONE_REGEX } from "./RegexLibrary";
import { ErrorSpan, Span, FlexColumnContainer } from "./ComponentsLibrary";

function Contact() {
  const data = useStaticQuery(CONTACT_QUERY);
  const [isLoading, setIsLoading] = useState(false);
  const [submitStatus, setSubmitStatus] = useState("");
  const { t } = useTranslation();
  const { register, handleSubmit, errors } = useForm({ mode: "onSubmit" });
  const onSubmit = async data => {
    setIsLoading(true);
    submit(`${process.env.GATSBY_CRONOS_ADMIN_BASE_URL}/contact/submit`, data)
      .then(() => setIsLoading(false))
      .catch(e => {
        console.error(e);
        setSubmitStatus(t("contactFormSubmissionFailed"));
        setIsLoading(false);
      });

    async function submit(url = "", data = {}) {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (response.status !== 204) {
        console.error(response.json);
        throw new Error("Exception while submitting form");
      } else {
        setSubmitStatus(t("contactFormSubmittedSuccessfully"));
      }
    }
  };

  return (
    <ContactStyled>
      <h1 id="contact">{t("contactTitle")}</h1>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <FlexColumnContainer>
          <ContactIconsComponent error={errors.name}>
            <Img fixed={data.Account.childImageSharp.fixed} alt="Account" />
            <StyledInput
              style={{ marginTop: 0 }}
              type="text"
              name="name"
              placeholder={t("nameSurname")}
              ref={register({
                required: {
                  value: true,
                  message: t("contactFieldIsRequired"),
                },
              })}
            />
          </ContactIconsComponent>
          {errors.name && <ErrorSpan>{errors.name.message}</ErrorSpan>}
          <ContactIconsComponent error={errors.email}>
            <Img fixed={data.Mail.childImageSharp.fixed} alt="Mail" />
            <StyledInput
              type="email"
              name="email"
              placeholder={t("emailAddress")}
              ref={register({
                required: {
                  value: true,
                  message: t("contactFieldIsRequired"),
                },
                pattern: {
                  value: EMAIL_REGEX,
                  message: t("contactInsertValidEmail"),
                },
              })}
            />
          </ContactIconsComponent>
          {errors.email && <ErrorSpan>{errors.email.message}</ErrorSpan>}
          <ContactIconsComponent error={errors.phone}>
            <Img fixed={data.Phone.childImageSharp.fixed} alt="Phone" />
            <StyledInput
              type="text"
              name="phone"
              placeholder={t("phoneNumber")}
              ref={register({
                required: {
                  value: true,
                  message: t("contactFieldIsRequired"),
                },
                pattern: {
                  value: PHONE_REGEX,
                  message: t("contactInsertValidPhone"),
                },
              })}
            />
          </ContactIconsComponent>
          {errors.phone && <ErrorSpan>{errors.phone.message}</ErrorSpan>}
        </FlexColumnContainer>
        <FlexColumnContainer>
          <StyledTextArea
            error={errors.feedback}
            name="feedback"
            placeholder={t("contactHowCanWeHelpYou")}
            ref={register({
              required: {
                value: true,
                message: t("contactFieldIsRequired"),
              },
              maxLength: {
                value: 1000,
                message: "You can introduce up to 1000 characters.",
              },
            })}
          />
          {errors.feedback && <ErrorSpan>{errors.feedback.message}</ErrorSpan>}
          {isLoading ? (
            <img
              style={{ marginTop: 8, width: 50, height: 50 }}
              src={LoadingGif}
              alt="Loading"
            />
          ) : (
            <>
              {submitStatus === "" ? (
                <ContactButton data-testid="button" type="submit">
                  {t("contactSend")}
                </ContactButton>
              ) : (
                <Span style={{ marginTop: 8 }}>{submitStatus}</Span>
              )}
            </>
          )}
        </FlexColumnContainer>
      </StyledForm>
    </ContactStyled>
  );
}

export default Contact;

const CONTACT_QUERY = graphql`
  query contact {
    Account: file(name: { eq: "contact-account" }, extension: { eq: "png" }) {
      childImageSharp {
        fixed(width: 20, height: 20) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    Phone: file(name: { eq: "contact-phone" }, extension: { eq: "png" }) {
      childImageSharp {
        fixed(width: 20, height: 20) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    Mail: file(name: { eq: "contact-mail" }, extension: { eq: "png" }) {
      childImageSharp {
        fixed(width: 20, height: 20) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
  }
`;

const focusCss = css`
  border: 1px solid #3d3d6d;
  outline: none;
  border-color: ${props => props.error && "#cc0000"};
`;

const autofillCss = css`
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    -webkit-text-fill-color: black;
    transition: background-color 5000s ease-in-out 0s;
  }
`;

const sharedInputTextAreaStyle = css`
  background: #fff;
  border: 1px solid #ccc;
  font-family: "Times New Roman", Times, serif;
  font-size: 1rem;
  font-weight: 400;
  padding: 10px 10px;

  ::placeholder {
    color: #9c9c9c;
    opacity: 1;
  }

  :focus {
    ${focusCss}
  }
  border-color: ${props => props.error && "#cc0000"};
  ${autofillCss}
`;

const ContactStyled = styled.main`
  padding: 2rem 20rem;
  align-items: center;

  @media (max-width: 1366px) {
    padding: 1rem 10rem;
  }

  @media (max-width: 1250px) {
    padding: 1rem;
    h1 {
      padding: 1rem 4rem;
    }
  }
`;

const StyledTextArea = styled.textarea`
  ${sharedInputTextAreaStyle}
  width: 25rem;
  height: 18rem;
  resize: none;
  margin-top: 30px;
  @media (max-width: 1250px) {
    margin-top: 1rem;
    width: 80vw;
    height: 30vh;
  }
`;

const StyledInput = styled.input`
  border: 0px;
  :focus {
    border: 0px;
    outline: none;
  }
  ${autofillCss}

  margin-left: 10px;

  @media (max-width: 1250px) {
    width: 70vw;
  }
`;

const StyledForm = styled.form`
  display: grid;
  grid-template-columns: 1fr 4fr;
  grid-column-gap: 3rem;
  grid-row-gap: 0.3rem;

  @media (max-width: 1250px) {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 0.2rem;
    justify-items: center;
  }
`;

const ContactButton = styled.button`
  display: inline-block;
  width: 25rem;
  border: 1px solid #fff;
  background: #3d3d6d;
  margin-top: 10px;
  color: #fff;
  text-decoration: none;
  text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3);
  box-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3);
  font-family: "Times New Roman", Times, serif;
  font-size: 0.8rem;
  height: 2rem;

  :hover {
    background: #3c6d43;
    border: 1px solid #3c6d43;
    color: white;
    text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3);
    box-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3);
    cursor: pointer;
  }

  @media (max-width: 1250px) {
    width: 80vw;
  }
`;

const ContactIconsComponent = styled.main`
  ${sharedInputTextAreaStyle}
  :focus-within {
    ${focusCss}
  }
  display: flex;
  align-items: center;
  margin-top: 30px;
  @media (max-width: 1250px) {
    width: 80vw;
  }
`;
