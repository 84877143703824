import React from "react";
import { ParallaxBanner, ParallaxProvider } from "react-scroll-parallax";
import { useStaticQuery, graphql } from "gatsby";

const STATIC_QUERY = graphql`
  query {
    file(name: { eq: "parallax" }, extension: { eq: "jpg" }) {
      childImageSharp {
        fluid(maxWidth: 4000, pngQuality: 3000) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`;

export default function Parallax() {
  const data = useStaticQuery(STATIC_QUERY);

  return (
    <ParallaxProvider>
      <ParallaxBanner
        className="banner"
        layers={[
          {
            image: data.file.childImageSharp.fluid.src,
            amount: 0.5,
          },
        ]}
        style={{
          height: "500px",
          maxWidth: "1920px",
          display: "flex",
          justifyContent: "center",
        }}
      />
    </ParallaxProvider>
  );
}
