import styled from "styled-components";

export const Line = styled.main`
  grid-column: 1 / -1;
  height: 1px;
  background: rgb(169, 169, 169, 0.3);
  margin-bottom: 20px;
`;

export const Span = styled.span`
  font-family: "Times New Roman", Times, serif;
  font-size: 0.8rem;
  font-weight: 600;
`;

export const ErrorSpan = styled(Span)`
  color: #cc0000;
  margin-left: 0.5rem;
`;

export const FlexColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FooterFlexColumnContainer = styled.main`
  display: flex;
  flex-direction: column;
  align-items: start;
  text-align: start;

  h2 {
    font-size: 1.2rem;
    margin-top: 10px;
  }

  @media (max-width: 768px) {
    grid-template-rows: 1fr 1.2fr;
    align-items: center;
  }
`;
