import React from "react";
import styled from "styled-components";
import Img from "gatsby-image";
import { useStaticQuery, graphql } from "gatsby";

import { useTranslation } from "react-i18next";

const BENEFITS_QUERY = graphql`
  query benefits {
    SaveTimeBenefit: file(name: { eq: "time" }, extension: { eq: "png" }) {
      childImageSharp {
        fixed(width: 80, height: 80) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    BarcodeBenefit: file(name: { eq: "barcode" }, extension: { eq: "png" }) {
      childImageSharp {
        fixed(width: 80, height: 80) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    FruitBoxBenefit: file(name: { eq: "fruit-box" }, extension: { eq: "png" }) {
      childImageSharp {
        fixed(width: 80, height: 80) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    DatabaseBenefit: file(
      name: { eq: "database-storage" }
      extension: { eq: "png" }
    ) {
      childImageSharp {
        fixed(width: 80, height: 80) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    SyncingBenefit: file(name: { eq: "syncing" }, extension: { eq: "png" }) {
      childImageSharp {
        fixed(width: 80, height: 80) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    InterfaceBenefit: file(name: { eq: "website" }, extension: { eq: "png" }) {
      childImageSharp {
        fixed(width: 80, height: 80) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    StatisticsBenefit: file(
      name: { eq: "research" }
      extension: { eq: "png" }
    ) {
      childImageSharp {
        fixed(width: 80, height: 80) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    ShiftsBenefit: file(name: { eq: "badge" }, extension: { eq: "png" }) {
      childImageSharp {
        fixed(width: 80, height: 80) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    SecurityBenefit: file(name: { eq: "security" }, extension: { eq: "png" }) {
      childImageSharp {
        fixed(width: 80, height: 80) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    PaymentBenefit: file(name: { eq: "payment" }, extension: { eq: "png" }) {
      childImageSharp {
        fixed(width: 80, height: 80) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    CommunityBenefit: file(
      name: { eq: "community" }
      extension: { eq: "png" }
    ) {
      childImageSharp {
        fixed(width: 80, height: 80) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    SupportBenefit: file(
      name: { eq: "customer-service" }
      extension: { eq: "png" }
    ) {
      childImageSharp {
        fixed(width: 80, height: 80) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
  }
`;

const BenefitsContainer = styled.main`
  padding: 2rem 20rem;
  align-items: start;
  background-color: #3c6d43;
  color: white;

  h2 {
    text-align: center;
    font-size: 1.5rem;
  }

  @media (max-width: 1366px) {
    padding: 1rem 10rem;
  }

  @media (max-width: 768px) {
    padding: 1rem;
  }
`;

const BenefitsGrid = styled.main`
  display: grid;
  grid-template-columns: 1fr 2fr;
  margin: 4rem 0;
  grid-gap: 20px 0;

  p {
    margin-bottom: 0.5rem;
    font-size: 1.1rem;
  }

  @media (max-width: 768px) {
    grid-template-columns: 2fr;
    grid-gap: 10px;
    margin: 0;
    text-align: center;
  }
`;

const FlexComponentIcon = styled.main`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const FlexComponentText = styled.main`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

function Benefits() {
  const data = useStaticQuery(BENEFITS_QUERY);
  const { t } = useTranslation();

  const benefits = [
    {
      fixed: data.SaveTimeBenefit.childImageSharp.fixed,
      alt: "SaveTimeBenefit",
      t: "benefitsSaveTime",
    },
    {
      fixed: data.BarcodeBenefit.childImageSharp.fixed,
      alt: "BarcodeBenefit",
      t: "benefitsBarcode",
    },
    {
      fixed: data.FruitBoxBenefit.childImageSharp.fixed,
      alt: "FruitBoxBenefit",
      t: "benefitsFruitBox",
    },
    {
      fixed: data.DatabaseBenefit.childImageSharp.fixed,
      alt: "DatabaseBenefit",
      t: "benefitsDatabase",
    },
    {
      fixed: data.SyncingBenefit.childImageSharp.fixed,
      alt: "SyncingBenefit",
      t: "benefitsSyncing",
    },
    {
      fixed: data.InterfaceBenefit.childImageSharp.fixed,
      alt: "InterfaceBenefit",
      t: "benefitsInterface",
    },
    {
      fixed: data.StatisticsBenefit.childImageSharp.fixed,
      alt: "StatisticsBenefit",
      t: "benefitsStatistics",
    },
    {
      fixed: data.ShiftsBenefit.childImageSharp.fixed,
      alt: "ShiftsBenefit",
      t: "benefitsShifts",
    },
    {
      fixed: data.SecurityBenefit.childImageSharp.fixed,
      alt: "SecurityBenefit",
      t: "benefitsSecurity",
    },
    {
      fixed: data.PaymentBenefit.childImageSharp.fixed,
      alt: "PaymentBenefit",
      t: "benefitsPayment",
    },
    {
      fixed: data.CommunityBenefit.childImageSharp.fixed,
      alt: "CommunityBenefit",
      t: "benefitsCommunity",
    },
    {
      fixed: data.SupportBenefit.childImageSharp.fixed,
      alt: "SupportBenefit",
      t: "benefitsSupport",
    },
  ];

  return (
    <BenefitsContainer>
      <h1 id="benefits">{t("benefitsTitle")}</h1>
      <h2 id="benefits">{t("benefitsSubtitle")}</h2>
      <BenefitsGrid>
        {benefits.map(benefit => (
          <React.Fragment key={benefit.alt}>
            <FlexComponentIcon>
              <Img fixed={benefit.fixed} alt={benefit.alt} />
            </FlexComponentIcon>
            <FlexComponentText>
              <p>{t(benefit.t)}</p>
            </FlexComponentText>
          </React.Fragment>
        ))}
      </BenefitsGrid>
    </BenefitsContainer>
  );
}

export default Benefits;
