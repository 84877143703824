import React, { useState } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";

import { EMAIL_REGEX } from "./RegexLibrary";
import {
  ErrorSpan,
  Span,
  FooterFlexColumnContainer,
} from "./ComponentsLibrary";
import LoadingGif from "../images/ajax-loader.gif";
import { useTranslation } from "react-i18next";

function NewsletterForm() {
  const [isLoading, setIsLoading] = useState(false);
  const [subscribeStatus, setSubscribeStatus] = useState(undefined);
  const { t } = useTranslation();
  const { register, handleSubmit, errors } = useForm({ mode: "onSubmit" });
  const onSubmit = async data => {
    setIsLoading(true);
    subscribe(
      `${process.env.GATSBY_CRONOS_ADMIN_BASE_URL}/newsletter/subscribe`,
      data
    )
      .then(() => setIsLoading(false))
      .catch(e => {
        console.error(e);
        setSubscribeStatus({
          message: t("footerSubscriptionFailed"),
          isError: true,
        });
        setIsLoading(false);
      });

    async function subscribe(url = "", data = {}) {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (response.status !== 204) {
        if (response.status === 409) {
          setSubscribeStatus({
            message: t("footerAlreadySubscribed"),
            isError: true,
          });
        } else {
          console.error(response.json);
          throw new Error("Exception while subscribing email");
        }
      } else {
        setSubscribeStatus({
          message: t("footerSuccessfullySubscribed"),
          isError: false,
        });
      }
    }
  };

  return (
    <FooterFlexColumnContainer>
      <p>{t("footerNews")}</p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FooterFlexColumnContainer>
          <SubscribeInput
            error={errors.email}
            type="email"
            name="email"
            placeholder={t("emailAddress")}
            ref={register({
              required: {
                value: true,
                message: t("contactFieldIsRequired"),
              },
              pattern: {
                value: EMAIL_REGEX,
                message: t("contactInsertValidEmail"),
              },
            })}
          />
          {errors.email && <ErrorSpan>{errors.email.message}</ErrorSpan>}
          {isLoading ? (
            <img
              style={{ marginTop: 8, width: 50, height: 50 }}
              src={LoadingGif}
              alt="Loading"
            />
          ) : (
            <>
              {!subscribeStatus ? (
                <SubscribeButton>{t("footerSubscribe")}</SubscribeButton>
              ) : subscribeStatus.isError ? (
                <ErrorSpan>{subscribeStatus.message}</ErrorSpan>
              ) : (
                <Span>{subscribeStatus.message}</Span>
              )}
            </>
          )}
        </FooterFlexColumnContainer>
      </form>
    </FooterFlexColumnContainer>
  );
}

const SubscribeInput = styled.input`
  background: #1f1f1f;
  color: #fff;
  border: 1px solid #fff;
  font-family: "Times New Roman", Times, serif;
  font-size: 0.8rem;
  width: 12rem;
  padding: 0 10px;
  border-color: ${props => props.error && "#cc0000"};

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    -webkit-text-fill-color: white;
    transition: background-color 5000s ease-in-out 0s;
  }
`;

const SubscribeButton = styled.button`
  display: inline-block;
  border-radius: 3px;
  width: 8rem;
  border: 1px solid #fff;
  background: #3d3d6d;
  margin-top: 10px;
  color: #fff;
  text-decoration: none;
  text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3);
  box-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3);
  font-family: "Times New Roman", Times, serif;
  font-size: 0.8rem;
  height: 2rem;

  :hover {
    background: #3c6d43;
    border: 1px solid #3c6d43;
    color: white;
    text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3);
    box-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3);
    cursor: pointer;
  }
`;

export default NewsletterForm;
