import React from "react";
import styled from "styled-components";
import Img from "gatsby-image";
import { useStaticQuery, graphql } from "gatsby";

import { useTranslation } from "react-i18next";

const ABOUT_QUERY = graphql`
  query about {
    FirstIcon: file(name: { eq: "money" }, extension: { eq: "png" }) {
      childImageSharp {
        fixed(width: 80, height: 80) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    SecondIcon: file(name: { eq: "savetree" }, extension: { eq: "png" }) {
      childImageSharp {
        fixed(width: 80, height: 80) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    ThirdIcon: file(name: { eq: "laptop" }, extension: { eq: "png" }) {
      childImageSharp {
        fixed(width: 80, height: 80) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    Motivation: file(name: { eq: "motivation" }, extension: { eq: "png" }) {
      childImageSharp {
        fixed(width: 200, height: 200) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    AppPreview: file(
      name: { eq: "about-app-preview" }
      extension: { eq: "jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 3000, pngQuality: 2000) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    CardPaymentPreferences: file(
      name: { eq: "about-cards-payment-preferences" }
      extension: { eq: "png" }
    ) {
      childImageSharp {
        fixed(width: 240, height: 380) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    CardRecordData: file(
      name: { eq: "about-cards-record-data" }
      extension: { eq: "png" }
    ) {
      childImageSharp {
        fixed(width: 240, height: 380) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    CardRecordsToday: file(
      name: { eq: "about-cards-records-today" }
      extension: { eq: "png" }
    ) {
      childImageSharp {
        fixed(width: 240, height: 380) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    CardDashboardReport: file(
      name: { eq: "about-cards-dashboard-report" }
      extension: { eq: "jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 3000, pngQuality: 2000) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`;

const AboutStyled = styled.main`
  padding: 2rem 20rem;
  align-items: center;

  h2 {
    color: #3c6d43;
    text-align: center;
    font-size: 1.5rem;
  }

  p {
    text-align: center;
    font-size: 1.1rem;
  }

  @media (max-width: 1366px) {
    padding: 1rem 10rem;
  }

  @media (max-width: 768px) {
    padding: 1rem;
    h2 {
      margin-top: 2rem;
      margin-bottom: 2rem;
    }
  }
`;

const GridStyled = styled.main`
  display: grid;
  grid-template-columns: 2fr 2fr 2fr;
  grid-gap: 20px;
  margin: 4rem 0;

  p {
    margin-bottom: 0.5rem;
  }

  @media (max-width: 1250px) {
    grid-template-columns: 2fr;
    grid-gap: 10px;
    margin: 0;
  }
`;

const FlexComponent = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  h2 {
    font-size: 1.2rem;
    margin-top: 10px;
  }

  @media (max-width: 768px) {
    grid-template-rows: 1fr 1.2fr;
  }
`;

const CardContainer = styled.main`
  box-shadow: 0 0 3px -1px;
  border-radius: 10px;
  overflow: hidden;

  @media (max-width: 1250px) {
    margin-top: 30px;
  }
`;

const CardContent = styled.main`
  margin: 1rem;
  margin-top: 0.5rem;
  max-width: 200px;
  font-size: 0.9rem;
`;

const CardContentForDashboard = styled.main`
  margin: 1rem;
  margin-top: 0.1rem;
  max-width: 100%;
  font-size: 0.9rem;
  text-align: center;
`;

function About() {
  const data = useStaticQuery(ABOUT_QUERY);
  const { t } = useTranslation();

  return (
    <AboutStyled>
      <h1 id="aboutUs">{t("aboutTitle")}</h1>
      <h2>{t("aboutPurpose")}</h2>
      <p>{t("aboutPurposeDetails")}</p>
      <p>{t("aboutHowItWorks")}</p>
      <GridStyled>
        <FlexComponent>
          <Img fixed={data.FirstIcon.childImageSharp.fixed} alt="FirstIcon" />
          <h2>{t("aboutSaveTimeMoney")}</h2>
          <p>{t("aboutSaveTimeMoneyDescription")}</p>
        </FlexComponent>
        <FlexComponent>
          <Img fixed={data.SecondIcon.childImageSharp.fixed} alt="SecondIcon" />
          <h2>{t("aboutLessPaper")}</h2>
          <p>{t("aboutLessPaperDescription")}</p>
        </FlexComponent>
        <FlexComponent>
          <Img fixed={data.ThirdIcon.childImageSharp.fixed} alt="ThirdIcon" />
          <h2>{t("aboutEasyAccessInfo")}</h2>
          <p>{t("aboutEasyAccessInfoDescription")}</p>
        </FlexComponent>
      </GridStyled>
      <h2>{t("aboutQuestionPaper")}</h2>
      <Img
        fluid={data.AppPreview.childImageSharp.fluid}
        alt="app-preview"
        style={{ marginBottom: "30px" }}
      />
      <p>{t("aboutManagerDevices")}</p>
      <p>{t("aboutAdministrator")}</p>
      <GridStyled>
        <FlexComponent>
          <CardContainer>
            <Img
              fixed={data.CardPaymentPreferences.childImageSharp.fixed}
              alt="CardPaymentPreferences"
            />
            <CardContent>{t("aboutCardPaymentPreferences")}</CardContent>
          </CardContainer>
        </FlexComponent>
        <FlexComponent>
          <CardContainer>
            <Img
              fixed={data.CardRecordData.childImageSharp.fixed}
              alt="CardRecordData"
            />
            <CardContent>{t("aboutCardRecordData")}</CardContent>
          </CardContainer>
        </FlexComponent>
        <FlexComponent>
          <CardContainer>
            <Img
              fixed={data.CardRecordsToday.childImageSharp.fixed}
              alt="CardRecordsToday"
            />
            <CardContent>{t("aboutCardRecordsToday")}</CardContent>
          </CardContainer>
        </FlexComponent>
      </GridStyled>

      <CardContainer>
        <Img
          fluid={data.CardDashboardReport.childImageSharp.fluid}
          alt="app-preview"
          style={{ marginBottom: "30px" }}
        />
        <CardContentForDashboard>{t("aboutCardDashboardReport")}</CardContentForDashboard>
      </CardContainer>
      
      <FlexComponent>
        <h2
          style={{
            marginTop: "2rem",
          }}
        >
          {t("aboutMotivation")}
        </h2>
        <Img
          fixed={data.Motivation.childImageSharp.fixed}
          alt="motivation"
          style={{ marginBottom: "20px" }}
        />
        <p>{t("aboutMotivationInfo")}</p>
      </FlexComponent>
    </AboutStyled>
  );
}

export default About;
